
import { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rodmap from "../assets/img/rodmap.png";
import vid from "../assets/img/forkvideo3.mp4";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';


export const Roadmap = () => {


  return (
    <section className="roadmap" id="Roadmap">
    <Container>
    <div class="remoteVideo-container">
    <video autoPlay playsinline muted loop className="remoteVideo">
        <source src={vid}/>
      </video>
      </div>
      
      </Container>
    </section>

  )
}
