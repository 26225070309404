import React from "react";
import { motion } from "framer-motion";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import rain from "./assets/img/forkfork.png";
import { NavBar } from "./components/NavBar";
import { About } from "./components/About";
import { Banner } from "./components/Banner";
import { Tokenomics } from "./components/Tokenomics";
import { Roadmap } from "./components/Roadmap";
import { Memes } from "./components/Memes";
import { Footer } from "./components/Footer";


export default function App() {
  return (
    <div className="App">
      <NavBar />
    {/* <div class="snowflakes">
      <div class="snowflake"><img src={rain}/></div>
      <div class="snowflake"><img src={rain}/></div>
      <div class="snowflake"><img src={rain}/></div>
      <div class="snowflake"><img src={rain}/></div>
      <div class="snowflake"><img src={rain}/></div>
      <div class="snowflake"><img src={rain}/></div>
      <div class="snowflake"><img src={rain}/></div>
      <div class="snowflake"><img src={rain}/></div>
    </div> */}
      <Roadmap />
      <Memes />
      <Footer />
    </div>
  );
}
